<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        laboralFunction.funcionLaboralId == 0
          ? $t("Routes.LaboralFunctionAdd")
          : $t("Routes.LaboralFunctionEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.laboralFunction.columns.name')"
              rules="required|max:50"
            >
              <b-form-group
                :label="$t('humanResources.laboralFunction.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="laboralFunction.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('humanResources.laboralFunction.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="laboralFunction.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Que"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('humanResources.laboralFunction.columns.what')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="laboralFunction.que" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="forWhat"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('humanResources.laboralFunction.columns.forWhat')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="laboralFunction.paraQue" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="resultArea"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('humanResources.laboralFunction.columns.resultArea')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="laboralFunction.areaResultados" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="indicator"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('humanResources.laboralFunction.columns.indicator')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="laboralFunction.indicador" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingLaboralFunction" small class="mr-1" />
            {{ laboralFunction.funcionLaboralId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, max } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,

    ValidationProvider,
    ValidationObserver,

  },
  setup() {
    const addingLaboralFunction = ref(false)
    const laboralFunction = ref({
      funcionLaboralId: 0,
      nombre: '',
      descripcion: '',
      que: '',
      paraQue: '',
      areaResultados: '',
      indicador: '',
      archivado: false,
    })

    const blankLaboralFunction = {
      funcionLaboralId: 0,
      nombre: '',
      abreviatura: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchLaboralFunction,
      createLaboralFunction,
      updateLaboralFunction,
    } = humanResources()

    const resetLaboralFunction = () => {
      laboralFunction.value = JSON.parse(JSON.stringify(blankLaboralFunction))
    }

    if (router.currentRoute.params.funcionLaboralId) {
      fetchLaboralFunction(router.currentRoute.params.funcionLaboralId, data => {
        laboralFunction.value = data
      })
    } else {
      laboralFunction.value = JSON.parse(JSON.stringify(blankLaboralFunction))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetLaboralFunction)

    const onSubmit = () => {
      addingLaboralFunction.value = true
      if (laboralFunction.value.funcionLaboralId === 0) {
        createLaboralFunction(laboralFunction.value, () => {
          addingLaboralFunction.value = false
          router.push({ name: 'apps-human-resources-laboral-function-list' })
        })
      } else {
        updateLaboralFunction(laboralFunction.value.funcionLaboralId, laboralFunction.value, () => {
          addingLaboralFunction.value = false
          router.push({ name: 'apps-human-resources-laboral-function-list' })
        })
      }
    }

    return {
      laboralFunction,
      addingLaboralFunction,
      required,
      max,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
